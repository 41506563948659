@use '../../styles.scss' as *;

.socials {
    a, p {
        font-size: max(1.4vw, 1.2rem);
        transition: 0.3s;

        &:hover {
            color: $accent;
        }
    }

   
    p {
        &:hover {
            cursor: not-allowed;
        }
        opacity: 0.5;
    }
}