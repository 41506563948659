@font-face {
  font-family: Satoshi;
  src: url('../../assets/Satoshi-Medium.woff');
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
  
    /* Make sticky */
    position: sticky;
    top: 0;
    width: 100%;
    font-weight: 500;
    z-index: 10;
    transition: 0.5s ease;
    
    // background-color: rgba(240, 240, 240, 0.3);
    backdrop-filter: blur(2px);
    // box-shadow: 1px 1px 5px 2px rgba(150, 150, 150, 50%);

    &:hover {
      backdrop-filter: blur(8px);
      background-color: rgba(205, 205, 205, 0.3);
      box-shadow: 1px 1px 5px 2px rgba(150, 150, 150, 50%);
    }
    
    button {
      text-decoration: none;
      background-color: transparent;
      padding: 1rem 0;
      position: relative;    
      font-family: Satoshi;
      transition: all 0.3s;
      font-size: 1.1rem;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: '.';
        color: transparent;
        background: theme('colors.accent');
        height: 3px;
        transition: width 0.3s;
      }
      &:hover:not(.navlogo):after {
        width: 100%;
      }
      &:hover {
        color: theme('colors.accent');
        background-color: transparent;
      }
      &:active {
        transform: none;
      }
    }
    .socialslink {
      position: absolute;
      right: 2rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .links {
      list-style: none;
      display: flex;
      gap: 3rem;
      justify-content: center;
      text-align: center;
    }

    .navlogo {
      position: absolute;
      left: 1.5rem;
      top: 1px;
      gap: 1rem;
      display: flex;
      align-items: center;
      padding: 0;
    }

    .burger {
      display: none;
      width: 100%;
      padding: 1rem;

      .hidden {
        display: none;
      }
      .shown {
        display: grid;
        padding: 25px;
        margin-top: 1.5rem;
      }
    }
}

.scrolled {
  backdrop-filter: blur(8px);
  background-color: rgba(205, 205, 205, 0.3);
  box-shadow: 1px 1px 5px 2px rgba(150, 150, 150, 50%);

  &:after {
    width: 100%;
  }

  &:hover {
    background-color: rgba(205, 205, 205, 0.6);
    backdrop-filter: blur(12px);
  }
}

@media screen and (max-width: 900px) {
  .nav {
    justify-content: left;
    .links {
      display: none;
    }
    .burger {
      display: block !important;
    }
    button {
      padding: 0;
    }
    .navlogo {
      height: 65px;
    }
    .socialslink {
      display: none;
    }
  }
  #socials {
    display: none;
  }
}

@media (prefers-reduced-motion) {
  .nav button:after {
    transition: none;
  }
}