@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

$accent: #548CA8;

@font-face {
  font-family: Satoshi;
  src: url('./assets/Satoshi-Medium.woff');
}

@font-face {
  font-family: Satoshi-Light;
  src: url('./assets/Satoshi-Regular.woff');
}

$debug: false;
@if $debug {
  * { 
    border: 1px solid red;
  }
}

@keyframes background-pan {
  from {
      background-position: 0% center;
  }
  to {
      background-position: -200% center;
  }
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}
  
* {
  &::selection {
    background-color: transparentize($accent, 0.75);
  }
}

body {
  background-size: 100%;
  background-color: theme('colors.background');
  background-size: cover;
  margin: 0;
}

.lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid theme('colors.accent');
  border-radius: 50%;
  border-top-color: transparent;
  background: none;
  animation: preloader-spin 1s infinite linear;
}

@keyframes preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button {
  background-color: lighten($accent, 15%);
  padding: 0.5vh 1vw;
  border-radius: 10px;
  transition: background-color 0.3s ease, transform 0.05s ease;

  &:hover {
    background-color: lighten($accent, 22%);
  }

  &:active {
    transform: scale(0.95);
  }
}

p, a {
  // font-size: max(1.1vw, 1rem);
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  line-height: 175%;
}
button {
  font-size: 1.1rem;
  font-family: 'Inter', sans-serif;
}

a:not(.socials a, #footer a, .style-none) {
  transition: all 0.3s;
  color: $accent;
  cursor: pointer;
  background-image: linear-gradient(90deg, rgba(84,140,168,1) 0%, rgba(84,140,168,1) 100%);;
  background-size: 0% 2.5px;
  background-repeat: no-repeat;
  background-position: left bottom;
  text-decoration: none;
  transition: color 0.3s, background-size 0.5s ease;

  &:hover {
    background-size: 100% 2.5px;
  }
}

h1, h2, h3, h4 {
  font-family: Satoshi, sans-serif;
  // font-weight: 400;
}

h1 {
  font-size: max(2.75rem, 6.5vw);
}

h2 {
  font-size: max(2.3rem, 4.5vw);
}

h3 {
  font-size: max(1.5rem, 2vw);
}

h4 {
  font-size: max(1.2rem, 1.6vw);
}

.section {
  padding: 5% 8%;

  h3 {
    // margin-bottom: 2rem;
    text-align: center;
  }

  div {
    height: 100%;

    .sectionwrapper {
      display: flex;
      justify-content: space-between;

      p {
        padding-top: 2rem;
      }
  
      img {
        min-width: 30%;
        align-self: center;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .section {
    div {
      .sectionwrapper {
        display: block;
        text-align: left;
        img {
          margin-top: 0px;
          margin-left: auto;
          margin-right: auto;
          width: 60%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .section {
    div {
      .sectionwrapper {
        img {
          width: 80%;
        }
      }
    }
  }
}

.gradient {
  background-clip: text;
  background-size: 200%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;  
  animation: background-pan 4s linear infinite;
}

.red {
  background-image: linear-gradient(90deg, rgba(235,153,60,1), rgba(184,31,31,1), rgba(235,153,60,1));
}
.blue {
  background-image: linear-gradient(90deg, rgba(25,197,229,1) 3%, rgba(191,39,228,1) 47%, rgba(25,197,229,1) 100%);
}
.darkblue {
  background-image: linear-gradient(90deg, rgba(27,210,33,1) 0%, rgba(223,224,34,1) 27%, rgba(0,212,255,1) 66%, rgba(27,210,33,1) 100%);
}  
.accent {
  background-image: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(84,140,168,1) 27%, rgba(45,91,113,1) 53%, rgba(84,140,168,1) 77%, rgba(0,212,255,1) 100%);
}
.green {
  background-image: linear-gradient(90deg, rgba(246,113,194,1) 0%, rgba(252,191,110,1) 11%, rgba(25,207,13,1) 49%, rgba(65,176,214,1) 77%, rgba(246,113,194,1) 100%);
}
.purple {
  background-image: linear-gradient(94deg, rgba(191,172,226,1) 0%, rgba(67,210,206,1) 30%, rgba(101,93,187,1) 76%, rgba(191,172,226,1) 97%);
}

.gradient-vertical {
  background: linear-gradient(0deg, rgba(235,153,60,1) 0%, rgba(184,31,31,1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;      
}

@media (prefers-reduced-motion) {
  * {
    transition: none !important;
  }
  html {
    scroll-behavior: auto;
  }
  .gradient {
    animation: none;
    animation-duration: 0s;
  }
}
