#hero {
    display: grid;
    place-items: center;
    
    transition: opacity 0.4s ease;
}

#description {    
    text-decoration: underline;
    text-decoration-color: theme('colors.accent');
}

.rw-sentence{
	text-align: left;
}
.rw-words{
	display: inline;
}
.rw-words-1 span{
	position: absolute;
    user-select: none;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
	opacity: 0;
	overflow: hidden;
	animation: rotateWord 24s linear infinite 0s;

    &:nth-child(2) { 
        animation-delay: 4s; 
    }

    &:nth-child(3) { 
        animation-delay: 8s; 
    }

    &:nth-child(4) { 
        animation-delay: 12s; 
    }

    &:nth-child(5) { 
        animation-delay: 16s; 
    }

    &:nth-child(6) { 
        animation-delay: 20s; 
    }
}

@keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); transform: translateY(-30px); }
	5% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
	20% { opacity: 0; -webkit-transform: translateY(30px); transform: translateY(30px); }
	100% { opacity: 0; }
}

@media screen and (max-width: 800px) {
    .rw-words-1 span {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        margin-top: 1vh;
        text-align: center;        
    }
}

@media (prefers-reduced-motion) {
    .rw-words-1 span {
        animation: none;

        &:last-child { 
            opacity: 1;
        }
    }
}